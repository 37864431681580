body {
  color: #fff;
  background: rgb(26, 0, 0);
  background-image: url(./images/plus-bg2.gif);
  background-size: 100px;
  cursor: url(http://ani.cursors-4u.net/nature/nat-11/nat1047.cur), auto !important;
}

.containerz {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
}