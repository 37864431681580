@import '../node_modules/react-modal-video/scss/modal-video.scss';

.heading {
    margin-top: 50px;
    margin-left: 200px;
}

.heading h3 {
    text-decoration: underline;
    font-size: 1.6em;
    margin-bottom: 0;
}

.heading p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 1.2em;

}

.flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto 200px;
}