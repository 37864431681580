.footer {
  text-align: center;
  margin-top: 65px;
}

.internet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: dotted pink 3px;
  padding-bottom: 50px;
  margin-bottom: 100px;
}

.dotted {
  border-top: dotted pink 3px;
  width: 750px;
  padding: 50px;
}

.counter {
  margin-top: 75px;
}

.footer-banner {
  cursor: pointer;
}