.logo {
  text-align: center;
  margin-top: 40px;
}

.button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 15px 3px;
  border: 5px outset #a19df1;
  background-image: linear-gradient(#7c76eb, #a49efb);
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.button:hover {
  background: rgb(248, 0, 194);
  border-color: rgb(248, 0, 194);
  color: white;
  margin-top: -1px;
}

.shiny {
  color: #C6CBF5;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 5.25em;
  line-height: 1.2;
  letter-spacing: 5px;
  text-align: center;
  text-transform: lowercase;
  width: 100%;
  background: -webkit-linear-gradient(top, rgb(4, 20, 54)35%, rgb(204, 204, 204) 50%, rgb(0, 0, 0) 50%, rgb(5, 167, 179) 60%, white 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.25px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.466);
  margin-top: -25px;
  margin-bottom: 0;
}

.slanted {
  color: #d100b1;
  font-family: 'Yellowtail', cursive;
  font-size: 4.7em;
  text-shadow: 0 0 1px #d100b1, 0 -3px 3px rgba(255, 255, 255, 0.8), 0 3px 3px rgba(0, 0, 0, 0.5), 0 0 15px #d100b1, 0 0 45px rgba(209, 0, 177, 0.8);
  text-align: center;
  text-decoration: underline;
  text-transform: none;
  -webkit-transform: skew(-10deg) rotate(-10deg);
  -ms-transform: skew(-10deg) rotate(-10deg);
  transform: skew(-10deg) rotate(-10deg);
  text-align: center;
  width: 100%;
  margin-top: -45px;
}

.fuego {
  text-align: center;
  margin: 15px 0 40px 0;
}

.intro {
  text-align: center;
  color: #5ee8fc;
  font-family: 'Times New Roman', Times, serif;
  margin: 10px auto 30px auto;
}

.intro p {
  font-size: 20px; 
}

.spacing {
  padding-left: 70px;
  color: #e53adc;
  font-size: 1.25em;
  font-weight: bold;
  animation: blink-animation 1s steps(5,start) infinite;
}

.bloo {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
