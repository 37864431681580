.ProfileCard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 8px;
  border: double rgb(250, 43, 171) 4px;
  position: relative;
}

img.email {
  width: 45px;
  position: absolute;
  top: 10px;
  right: 30px;
}

.tester {
  background: rgba(250, 250, 250, .4);
}

.profile-image {
  padding: 5px;
  height: auto;
}

.profile-image .actual-profile-image {
  width: 170px;
  height: auto;
  border: solid pink 2px;
}

.profile-image img:hover {
  border: solid white 1px;
  cursor: pointer;
}

.description {
  padding: 5px;
  width: 250px;
  height: 132.641px;
  text-align: center;
}

.description h4 {
  font-size: 1.5em;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: left;
  padding-left: 5px;
  color: #10eb11;
  font-weight: 100;
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.description p {
  margin-top: 0;
  text-align: left;
  padding: 5px;
  font-family: 'Courier New', Courier, monospace;
  color: pink;
}

#cta {
  font-family: 'Courier New', Courier, monospace;
  font-size: .8em;
  text-transform: uppercase;
  margin-top: 4px;
  /* height: 12px; */
  padding: 2px;
  /* text-shadow: -1px -1px 1px rgba(0, 0, 0, .8); */
  border: 5px solid transparent;
  border-top-color: rgba(255, 255, 255, .2);
  border-right-color: rgba(0, 0, 0, 0.3);
  border-bottom-color: rgba(0, 0, 0, 0.8);
  background: rgb(97, 97, 97);
  cursor: pointer;
  outline: 0 !important;
}

#cta:hover {
  background: rgb(248, 0, 194);
  margin-top: -1px;
}

#cta:active {
  border-bottom-width: 0;
  margin-top: 5px;
}

.profile-button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: .75em;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 5px 5px;
  border: 5px outset #a19df1;
  background-image: linear-gradient(#7c76eb, #a49efb);
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.profile-button:hover {
  background: rgb(248, 0, 194);
  border-color: rgb(248, 0, 194);
  color: white;
  margin-top: -1px;
}

.heart-gif {
  position: absolute;
  bottom: -60px;
  width: 50px;
}

@media only screen and (max-width: 700px) {
 .ProfileCard {
   margin: 20px 0 20px 300px;
 }
}

.video-container-holder {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  background: rgba(0, 0, 0, .75);
}

.video-container {
  align-self: center;
  padding: 25px;
  background: rgb(95, 95, 95);
  border: 2px outset grey;
  width: 400px;
  text-align: center;
}

.video {
  width: 400px;
  display: block;
}

.close {
  display: block;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.vid-buttons {
  margin: 10px;
  display: inline-block;
  padding: 5px;
  border: 2px outset grey;
}

.vid-buttons:active {
  margin: 10px;
  display: inline-block;
  padding: 5px;
  border: 2px outset black;
}

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s 6s infinite both;
	        animation: jello-horizontal 0.9s 6s infinite both;
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}