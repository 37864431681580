h3 {
  text-decoration: underline;
  font-size: 1.6em;
  margin-bottom: 0;
}

.flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 950px;
  margin: auto;
}

.meet-intro {
  background: black;
  border: outset pink 5px; 
  padding: 15px;
  width: 800px;
  margin: 50px auto 0 auto;
  background-image: url(./images/sparkles.gif);
}

.meet-intro h1 {
  font-family: arial;
  background-image: linear-gradient(orange, yellow);
  -webkit-background-clip: text;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  font-size: 3.5em;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.meet-intro p {
  font-family: cursive;
  font-size: 1.5em;
}

.newsflash {
  padding-top: 10px;
  margin-left: -150px;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .flexbox {
    width: unset;
  }
  .tester {

  }
}

.bannerexit {
  cursor: pointer;
}