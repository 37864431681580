.logo {
  text-align: center;
  margin-top: 40px;
}

.button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.1em;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 15px 3px;
  border: 5px outset #a19df1;
  background-image: linear-gradient(#7c76eb, #a49efb);
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.button:hover {
  background: rgb(248, 0, 194);
  border-color: rgb(248, 0, 194);
  color: white;
  margin-top: -1px;
}

.shiny {
  color: #C6CBF5;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 5.25em;
  line-height: 1.2;
  letter-spacing: 5px;
  text-align: center;
  text-transform: lowercase;
  width: 100%;
  background: -webkit-linear-gradient(top, rgb(4, 20, 54)35%, rgb(204, 204, 204) 50%, rgb(0, 0, 0) 50%, rgb(5, 167, 179) 60%, white 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1.25px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.466);
  margin-top: -25px;
  margin-bottom: 0;
}

.slanted {
  color: #d100b1;
  font-family: 'Yellowtail', cursive;
  font-size: 4.7em;
  text-shadow: 0 0 1px #d100b1, 0 -3px 3px rgba(255, 255, 255, 0.8), 0 3px 3px rgba(0, 0, 0, 0.5), 0 0 15px #d100b1, 0 0 45px rgba(209, 0, 177, 0.8);
  text-align: center;
  text-decoration: underline;
  text-transform: none;
  -webkit-transform: skew(-10deg) rotate(-10deg);
  transform: skew(-10deg) rotate(-10deg);
  text-align: center;
  width: 100%;
  margin-top: -45px;
}

.fuego {
  text-align: center;
  margin: 15px 0 40px 0;
}

.intro {
  text-align: center;
  color: #5ee8fc;
  font-family: 'Times New Roman', Times, serif;
  margin: 10px auto 30px auto;
}

.intro p {
  font-size: 20px; 
}

.spacing {
  padding-left: 70px;
  color: #e53adc;
  font-size: 1.25em;
  font-weight: bold;
  -webkit-animation: blink-animation 1s steps(5,start) infinite;
          animation: blink-animation 1s steps(5,start) infinite;
}

.bloo {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

body {
  color: #fff;
  background: rgb(26, 0, 0);
  background-image: url(/static/media/plus-bg2.7715c270.gif);
  background-size: 100px;
  cursor: url(http://ani.cursors-4u.net/nature/nat-11/nat1047.cur), auto !important;
}

.containerz {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  pointer-events: none;
}
.ProfileCard {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
  padding: 8px;
  border: double rgb(250, 43, 171) 4px;
  position: relative;
}

img.email {
  width: 45px;
  position: absolute;
  top: 10px;
  right: 30px;
}

.tester {
  background: rgba(250, 250, 250, .4);
}

.profile-image {
  padding: 5px;
  height: auto;
}

.profile-image .actual-profile-image {
  width: 170px;
  height: auto;
  border: solid pink 2px;
}

.profile-image img:hover {
  border: solid white 1px;
  cursor: pointer;
}

.description {
  padding: 5px;
  width: 250px;
  height: 132.641px;
  text-align: center;
}

.description h4 {
  font-size: 1.5em;
  margin-bottom: 0;
  margin-top: 5px;
  text-align: left;
  padding-left: 5px;
  color: #10eb11;
  font-weight: 100;
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.description p {
  margin-top: 0;
  text-align: left;
  padding: 5px;
  font-family: 'Courier New', Courier, monospace;
  color: pink;
}

#cta {
  font-family: 'Courier New', Courier, monospace;
  font-size: .8em;
  text-transform: uppercase;
  margin-top: 4px;
  /* height: 12px; */
  padding: 2px;
  /* text-shadow: -1px -1px 1px rgba(0, 0, 0, .8); */
  border: 5px solid transparent;
  border-top-color: rgba(255, 255, 255, .2);
  border-right-color: rgba(0, 0, 0, 0.3);
  border-bottom-color: rgba(0, 0, 0, 0.8);
  background: rgb(97, 97, 97);
  cursor: pointer;
  outline: 0 !important;
}

#cta:hover {
  background: rgb(248, 0, 194);
  margin-top: -1px;
}

#cta:active {
  border-bottom-width: 0;
  margin-top: 5px;
}

.profile-button {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: .75em;
  font-weight: bold;
  letter-spacing: .1em;
  text-transform: uppercase;
  padding: 5px 5px;
  border: 5px outset #a19df1;
  background-image: linear-gradient(#7c76eb, #a49efb);
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.profile-button:hover {
  background: rgb(248, 0, 194);
  border-color: rgb(248, 0, 194);
  color: white;
  margin-top: -1px;
}

.heart-gif {
  position: absolute;
  bottom: -60px;
  width: 50px;
}

@media only screen and (max-width: 700px) {
 .ProfileCard {
   margin: 20px 0 20px 300px;
 }
}

.video-container-holder {
  position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 7;
  background: rgba(0, 0, 0, .75);
}

.video-container {
  align-self: center;
  padding: 25px;
  background: rgb(95, 95, 95);
  border: 2px outset grey;
  width: 400px;
  text-align: center;
}

.video {
  width: 400px;
  display: block;
}

.close {
  display: block;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.vid-buttons {
  margin: 10px;
  display: inline-block;
  padding: 5px;
  border: 2px outset grey;
}

.vid-buttons:active {
  margin: 10px;
  display: inline-block;
  padding: 5px;
  border: 2px outset black;
}

.jello-horizontal {
	-webkit-animation: jello-horizontal 0.9s 6s infinite both;
	        animation: jello-horizontal 0.9s 6s infinite both;
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
 @-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
.heading {
    margin-top: 50px;
    margin-left: 200px;
}

.heading h3 {
    text-decoration: underline;
    font-size: 1.6em;
    margin-bottom: 0;
}

.heading p {
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 1.2em;

}

.flexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto 200px;
}
h3 {
  text-decoration: underline;
  font-size: 1.6em;
  margin-bottom: 0;
}

.flexbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 950px;
  margin: auto;
}

.meet-intro {
  background: black;
  border: outset pink 5px; 
  padding: 15px;
  width: 800px;
  margin: 50px auto 0 auto;
  background-image: url(/static/media/sparkles.89020782.gif);
}

.meet-intro h1 {
  font-family: arial;
  background-image: linear-gradient(orange, yellow);
  -webkit-background-clip: text;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  font-size: 3.5em;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.meet-intro p {
  font-family: cursive;
  font-size: 1.5em;
}

.newsflash {
  padding-top: 10px;
  margin-left: -150px;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .flexbox {
    width: unset;
  }
  .tester {

  }
}

.bannerexit {
  cursor: pointer;
}
.footer {
  text-align: center;
  margin-top: 65px;
}

.internet {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: dotted pink 3px;
  padding-bottom: 50px;
  margin-bottom: 100px;
}

.dotted {
  border-top: dotted pink 3px;
  width: 750px;
  padding: 50px;
}

.counter {
  margin-top: 75px;
}

.footer-banner {
  cursor: pointer;
}
